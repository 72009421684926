.logo {
    position:relative;
    top:-6px;
    width: 170px;
    height: 60px;
    transition: all 0.3s ease-out;
}

.logo:hover{
    transform: scale(1.02);
}

.toolbar {
    display:flex;
    justify-Content:space-between;
    background-image: url("../images/iconossolos.png");
    background-repeat: repeat-x;
    background-size: contain;
}

.avatar{
    position: relative;
    left: -5px;
    top: 0px;
}

.avatar-contain{
    background-image: url("../images/pestanaverde.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    right: -15px;
}
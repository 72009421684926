.card-component {
  padding: 2px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 1;
  position: relative;
  box-shadow: 0 0 5px rgb(1, 16, 45);
  border-radius: 15px;
  text-align: center;
  background: rgb(202, 210, 38, 0.9);
  background: linear-gradient(
    180deg,
    rgba(202, 210, 38, 1) 0%,
    rgba(208, 214, 90, 1) 35%,
    rgba(230, 235, 141, 1) 100%
  );
  color: #0e3b5f;
  font-weight: bold;
  font-size: 1rem;
}

.card-component:hover {
  cursor: pointer;
  color: rgb(42, 56, 3);
  box-shadow: 0 0 10px rgb(1, 16, 45);
  /* transform: translateY(-5px); */
  background-color: rgba(14, 59, 95, 0.888);
}

.title {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .title {
    margin-top: 8px;
    font-size: 12px;
  }

  .card-component:hover {
    transform: scale(1.06);
  }
}

.login-root{
    height: 100vh;
    width: 100vw;
    padding:20px;
    box-shadow: 9px 9px 5px -2px rgba(0,0,0,0.59);
}

.login-sub-root{
    min-height: 500px;
    border-radius: 5px;
}

.login-cenefa{
    max-height: 170px;
    margin:0px;
    padding:0px;
    width: 101px;
}

.login-texfield, .login-button{
    padding:10px;
    margin:0px 20px 20px 20px;
}

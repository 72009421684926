.container-image-one {
  width: 250px;
  height: 140px;
  z-index: 501;
  position: relative;
  top: -10px;
}

.ant-image-preview-body img {
  border: 8px solid #CAD226; /* Marco verde */
  border-radius: 8px; /* Opcional: esquinas redondeadas */
}


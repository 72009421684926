
.button{
    color:#CAD226;
    border:1px solid #CAD226;
    background-color: white;
    padding:10px;
    border-radius: 5px;
    height: 30px;
    line-height: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap:5px;
    transition:all 0.2s ease-in-out;
}

.button-active{
    color:white;
    border:2px solid #aaa;
    background-color: #CAD226;
    padding:10px;
    border-radius: 5px;
    height: 30px;
    line-height: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap:5px;
    transition:all 0.2s ease-in-out;
}


.button:hover, .button-active:hover{
    cursor: pointer;
    transform: translateY(-1.5px);
    box-shadow: 0 0 2px rgb(128, 137, 1);
}
.root{
    width: 100%;
}
.card-component-welcome {
    padding: 2px;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    gap: 1;
    position: relative;
    box-shadow: 0 0 5px rgb(14, 16, 128);
    border-radius: 15px;
    /* transition: all 0.3s ease-in-out; */
    text-align: center;
    background: rgb(14,59,95);
    background: linear-gradient(180deg, rgba(14,59,95,1) 0%, rgba(23,84,134,1) 35%, rgba(53,108,152,1) 100%);
    color: white;
    line-height: 0px;
  }
  
  .title-welcome-one {
    /* margin-top: 10px; */
    font-weight: bold;
    font-size: 16px;
    color:#DCDCDC;
    position: absolute;
    top:15px;
    left:10px;
  }

  .title-welcome-name {
    /* margin-top: 10px; */
    font-weight: bold;
    font-size: 16px;
    color:white;
    width: 100%;
    text-wrap: wrap;
    margin-top: 10px;
  }

  .title-welcome-date {
    /* margin-top: 10px; */
    font-weight: bold;
    font-size: 12px;
    color:#ffffff;
    position: absolute;
    bottom:10px;
    left:10px;
  }

  .title-welcome-hour {
    /* margin-top: 10px; */
    font-weight: bold;
    font-size: 12px;
    color:#ffffff;
    position: absolute;
    bottom:10px;
    right:10px;
  }

  .container-clima {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1;
    color: white;
  }
  
  .card-component-welcome:hover {
    cursor: pointer;
    color: white;
    box-shadow: 0 0 10px rgb(6, 8, 85);
    /* transform: translateY(-5px); */
    /* transform: scale(1.05); */
    background-color:rgb(14, 59, 95);
  }
  
  @media (max-width: 1200px) {


    .title-welcome-name{
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    .title-welcome {
      margin-top: 8px;
      font-size: 12px;
    }
  

    .title-welcome-name{
      font-size: 12px;
    }

    .title-welcome-date {
      font-size: 9px;
    }
  }
  